import React, { lazy, useCallback, useMemo } from 'react';
import { addReactionToIdea, IdeaPayload, joinIdea, leaveIdea, likeIdea, removeMemberFromIdea, removeReactionFromIdea, unlikeIdea } from '../../services/idea.service';


import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useAuth } from '../../services/useAuth';
import { User } from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { IdeaSummary } from './IdeaSummary';

import { Reactions } from '../Reactions/Reactions';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import Stack from '@mui/material/Stack/Stack';
import Button from '@mui/material/Button/Button';
import Chip from '@mui/material/Chip/Chip';
import { useIdea } from '../../hooks/useIdea';

dayjs.extend(relativeTime);

export const IdeaCard = ({
    hackathonId,
    ideaId,
    maxTeamSize,
    isOrganizer,
    onDelete,
    onUnarchive,
    canJoin,
    onReloadIdea
}: {
    hackathonId: string,
    ideaId: string,
    isOrganizer: boolean,
    maxTeamSize?: number,
    onUnarchive?: () => void,
    onDelete?: () => void,
    onReloadIdea?: () => void,
    canJoin?: boolean
}) => {

    const { idea } = useIdea(hackathonId, ideaId);
    const reactions = useMemo(() => {
        if (!idea) return [];

        return [...idea.reactions];
    }, [idea])
    const { user } = useAuth();

    const userId = useMemo(() => user ? user._id : '', [user]);

    const navigate = useNavigate();


    const liked = useMemo(() => {
        if (!idea) return false;
        return idea.likedBy.some(l => l._id === userId)
    }, [idea, userId])

    const like = useCallback(() => {
        if (!idea) return false;
        likeIdea(hackathonId, idea._id)
        idea.likedBy.push(user as User)
    }, [idea, hackathonId])

    const unlike = useCallback(() => {
        if (!idea) return false;
        unlikeIdea(hackathonId, idea._id)
        idea.likedBy = idea.likedBy.filter(l => l._id !== userId);
    }, [idea, hackathonId])

    const [processing, setProcessing] = React.useState(false);

    const isOwner = useMemo(() => {
        if (!idea) return false;
        return idea.createdBy._id === userId;
    }, [idea, userId])

    const isMember = useMemo(() => {
        if (!idea) return false;
        return idea.members.some(m => m._id === userId);
    }, [idea, userId])

    if (!idea) {
        return <Box>
            <Typography>Idea not found</Typography>
        </Box>
    }

    return (
        <Box id={idea._id}>
            <Stack spacing={2} direction={'column'}>
                <IdeaSummary
                    isInTeam={isMember}
                    hackathonId={hackathonId}
                    idea={idea}
                    isOrganizer={isOrganizer}
                    canEdit={isOwner || isOrganizer || isMember} 
                    canDelete={isOwner || isOrganizer}
                    onDelete={onDelete}
                    onUnarchive={onUnarchive}
                    onEdit={onReloadIdea}
                />
                <Stack padding={2} sx={{ backgroundColor: 'rgb(240, 240, 240)' }} spacing={2} direction={'column'}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <h4>Team</h4>
                        {
                            isMember && <Button color={'error'} variant={'outlined'} onClick={() => {
                                if (processing) return;
                                setProcessing(true);
                                leaveIdea(hackathonId, idea._id).then(() => {
                                    setProcessing(false);
                                    onReloadIdea && onReloadIdea();
                                })
                            }}>Leave team</Button>
                        }
                        {
                            !isMember && !idea.disqualified && <Button
                                disabled={!(canJoin && (!maxTeamSize || idea.members.length < maxTeamSize))}

                                variant={'outlined'}

                                onClick={() => {
                                    joinIdea(hackathonId, idea._id).then(() => {
                                        setProcessing(false);
                                        onReloadIdea && onReloadIdea();
                                    })
                                }}>Join team</Button>
                        }
                    </Stack>
                    {idea.members.length > 0 && (
                        <Stack direction={'row'} spacing={2}>
                            {
                                idea.members.map((member) => (
                                    isOrganizer ?
                                        <Chip key={member._id} label={member.username} onDelete={() => {
                                            // remove member from team
                                            removeMemberFromIdea(hackathonId, idea._id, member.email).then(() => {
                                            }, (err) => {
                                                console.error(err);
                                            });
                                        }} /> :
                                        <Chip key={member._id} label={member.username} />
                                ))
                            }
                        </Stack>
                    )}

                    {
                        idea.members.length === 0 && <Typography>No team members</Typography>
                    }
                </Stack>

                <Stack direction={'row'} spacing={2}>
                    <Button variant={'outlined'} onClick={() => navigate(`/h/${hackathonId}/idea/${idea._id}`)}>{idea.comments.length} Comments</Button>
                    <Button variant={'outlined'} onClick={() => {
                        liked ? unlike() : like()
                    }}>{liked ? 'Unlike' : 'Like'}&nbsp;&bull;&nbsp;{idea.likedBy.length}</Button>
                </Stack>

                <Reactions
                    reactions={reactions}
                    user={user as User}
                    onAddReaction={(emoji: string) => {
                        addReactionToIdea(hackathonId, idea._id, emoji).then(() => {
                        }, (err) => {
                            console.error(err);
                        })
                    }}
                    onRemoveReaction={(emoji: string) => {
                        removeReactionFromIdea(hackathonId, idea._id, emoji).then(() => {
                        }, (err) => {
                            console.error(err);
                        })
                    }} />

            </Stack>
        </Box>
    )
}