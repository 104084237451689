import React, { useMemo } from 'react';

import { HackathonPayload } from '../../services/hackathon.service';
import { IdeaCard } from './IdeaCard';
import { NewIdea } from './NewIdea';
import { useAuth } from '../../services/useAuth';
import Stack from '@mui/material/Stack/Stack';
import Alert from '@mui/material/Alert/Alert';
import Button from '@mui/material/Button/Button';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import FormControl from '@mui/material/FormControl/FormControl';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Card from '@mui/material/Card/Card';
import CardContent from '@mui/material/CardContent/CardContent';
import Box from '@mui/material/Box/Box';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import { IdeaPayload } from '../../services/idea.service';
import List from '@mui/material/List/List';
import ListItemButton from '@mui/material/ListItemButton/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useDesktopMode } from '../../services/hooks/useDesktopMode';
import ListItem from '@mui/material/ListItem/ListItem';
export const IdeasList = ({
    hackathon,
    reloadHackathon
}: {
    hackathon: HackathonPayload,
    reloadHackathon: () => void
}) => {

    const desktopMode = useDesktopMode();
    const { user } = useAuth();
    const [hideFullTeams, setHideFullTeams] = React.useState(false);
    const [sortBy, setSortBy] = React.useState<'newest' | 'likes'>('likes');
    const newIdeaElement = React.useRef<HTMLElement | null>(null);

    const [showNotification, setShowNotification] = React.useState(false);
    const [notificationMessage, setNotificationMessage] = React.useState('');

    const isInTeam = useMemo(() => {
        return !!user && hackathon.ideas.some(idea => idea.members.some(m => m.email === user.email));
    }, [hackathon.ideas, user])

    const canJoin = useMemo(() => {
        const hackathonStarted = !hackathon.startDate || (hackathon.startDate && hackathon.startDate.getTime() > Date.now());
        return Boolean(
            !!user && !isInTeam && !!hackathon && hackathonStarted
        )
    }, [isInTeam, user, hackathon])

    const isOrganizer = useMemo(() => {
        return !!user && hackathon.organizers.some(o => o.email === user.email);
    }, [user, hackathon.organizers])

    const isJudge = useMemo(() => {
        return !!user && hackathon.judges.some(j => j.email === user.email);
    }, [user, hackathon.judges])

    const TWO_DAYS = 1000 * 60 * 60 * 24 * 2;
    const ideaSubmissionDeadlineTimeRemaining = hackathon.ideaSubmissionDeadline ? new Date(hackathon.ideaSubmissionDeadline).getTime() - Date.now() : TWO_DAYS;
    const list = useMemo(() => {
        return hackathon.ideas.filter(i => (isOrganizer || (!isOrganizer && !i.archived))).sort((a, b) => {
            // current team is always at top
            if (!!user) {
                if (a.members.some(m => m.email === user.email))
                    return -1;
                if (b.members.some(m => m.email === user.email))
                    return 1;
            }
            switch (sortBy) {
                case 'likes':
                    return b.likedBy.length - a.likedBy.length;
                case 'newest':
                    return new Date(b.submittedAt).getTime() - new Date(a.submittedAt).getTime();
                default:
                    return 0;
            }
        }).filter(idea => {
            if (hideFullTeams) {
                return idea.members.length < hackathon.maxTeamSize;
            }
            return true;
        });
    }, [hackathon, hackathon.ideas, user, hideFullTeams, sortBy, isOrganizer])

    const elementRef = React.useRef<HTMLDivElement | null>(null);
    const leftSpace = elementRef.current?.getBoundingClientRect().left ?? 0;

    return (
        <Stack ref={elementRef} direction="column" spacing={4}>
            {
                list.length > 2 && leftSpace > 310 && <Box sx={{
                    position: 'fixed',
                    top: 50,
                    left: 0,
                    zIndex: 100,
                    backgroundColor: '#fff',
                    padding: 1,
                    borderBottom: '1px solid #ccc',
                    width: 300,
                    height: 'calc(100vh - 100px)',
                    overflow: 'auto'
                }}>
                    <List>
                        {list.map((idea: IdeaPayload) => {
                            return (
                                <ListItemButton key={idea._id} onClick={() => {
                                    const el = document.getElementById(idea._id);
                                    if (el) {
                                        el.scrollIntoView({ behavior: 'smooth' });
                                    }
                                }}>
                                    <ListItemText primary={idea.title} />
                                </ListItemButton>
                            )
                        })}
                        {
                            ideaSubmissionDeadlineTimeRemaining > 0 && <ListItem>
                                <Button variant={'contained'} color={'primary'} onClick={() => {
                                    newIdeaElement.current?.scrollIntoView({ behavior: 'smooth' });
                                }}>
                                    Submit an idea
                                </Button>
                            </ListItem>
                        }


                    </List>
                </Box>
            }
            <Stack direction="column" padding={2} spacing={2} sx={{ backgroundColor: '#f0f0f0' }}>
                {list.length > 0 && !isInTeam && !isOrganizer && !isJudge && (<Alert severity="info">
                    FYI: you are not in a team yet. You can join a team by clicking on the "Join team" button on the idea card.
                </Alert>)}
                <Stack spacing={2} direction="row" alignItems="center" justifyContent={'space-between'}>
                    <h2>Ideas {list.length > 0 ? `(${list.length})` : ''}</h2>
                    {
                        list.length > 3 && <Button onClick={() => {
                            newIdeaElement.current?.scrollIntoView({ behavior: 'smooth' });
                        }}>Skip to idea submission</Button>
                    }
                    <Stack spacing={2} direction="row" alignItems="center">
                        <FormGroup row={true}>
                            <FormControlLabel
                                control={<Checkbox checked={hideFullTeams} onChange={(e) => setHideFullTeams(e.target.checked)} />}
                                label={'Hide full teams'} />
                            <FormControl>
                                <Select
                                    label="Sort by"
                                    value={sortBy}
                                    onChange={(e) => setSortBy(e.target.value as 'newest' | 'likes')}
                                    variant={'standard'}
                                >
                                    <MenuItem value={'newest'}>Sort Newest</MenuItem>
                                    <MenuItem value={'likes'}>Sort Likes</MenuItem>
                                </Select>
                            </FormControl>

                        </FormGroup>

                    </Stack>
                </Stack>
                {
                    list.length === 0 && <p>No ideas submitted yet</p>
                }
                {
                    list.map((idea: IdeaPayload) => {
                        return (
                            <Card key={idea._id} variant="outlined" id={idea._id}>
                                <CardContent>
                                    <IdeaCard
                                        isOrganizer={isOrganizer}
                                        hackathonId={hackathon.slug}
                                        ideaId={idea._id}
                                        canJoin={canJoin}
                                        maxTeamSize={hackathon.maxTeamSize}
                                        onUnarchive={() => {
                                            reloadHackathon();
                                        }}
                                        onDelete={() => {
                                            reloadHackathon();
                                        }}
                                        onReloadIdea={() => {
                                            reloadHackathon();
                                        }}
                                    />

                                </CardContent>
                            </Card>
                        )
                    })
                }
            </Stack>

            <Box ref={newIdeaElement}>
                <Stack direction="column" spacing={2}>
                    {
                        ideaSubmissionDeadlineTimeRemaining > 0 && ideaSubmissionDeadlineTimeRemaining < TWO_DAYS && (
                            <Alert severity="warning">
                                Idea submission deadline is in {Math.floor(ideaSubmissionDeadlineTimeRemaining / (1000 * 60 * 60))} hours
                            </Alert>
                        )
                    }
                    {
                        ideaSubmissionDeadlineTimeRemaining < 0 && (
                            <Alert severity="error">
                                Idea submission deadline has passed
                            </Alert>
                        )
                    }
                    {
                        ideaSubmissionDeadlineTimeRemaining > 0 && (
                            <NewIdea hackathonId={hackathon._id} template={hackathon.ideaTemplate} onCreate={(ideaId) => {
                                reloadHackathon();
                                setShowNotification(true);
                                setNotificationMessage('Thank you for submitting your idea!');
                                setTimeout(() => {
                                    // scroll to the newly created idea
                                    const el = document.getElementById(ideaId);
                                    if (el) {
                                        el.scrollIntoView({ behavior: 'smooth' });
                                    }
                                }, 700)
                            }} />
                        )
                    }
                </Stack>


            </Box>
            <Snackbar
                open={showNotification}
                autoHideDuration={6000}
                onClose={() => {
                    setShowNotification(false);
                }}
                message={notificationMessage}
                color={notificationMessage.includes('error') ? 'error' : 'success'}
            />
        </Stack>
    )

}