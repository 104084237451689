import React, { useCallback, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useHackathon } from '../../hooks/useHackathon';
import { Loading } from '../../components/Loading';
import { useAuth } from '../../services/useAuth';
import { PageContainer } from '../../components/PageContainer';
import { MemberMenu } from '../../components/Hackathon/MemberMenu';
import Stack from '@mui/material/Stack/Stack';
import Button from '@mui/material/Button/Button';
import Box from '@mui/material/Box/Box';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import { AppBreadcrumbs, PageType } from '../../components/Breadcrumbs/Buttons';
import { JoinTeamMenu } from '../../components/Idea/JoinTeamMenu';
import { RemoveFromTeamButton } from '../../components/Idea/RemoveFromTeamButton';
import Typography from '@mui/material/Typography/Typography';

export const OrganizerPage = () => {

    const { id } = useParams();

    const { hackathon, loading, error, reload } = useHackathon(id);

    const { user: curUser } = useAuth();
    const [hideJudges, setHideJudges] = React.useState(false);
    const [hideInTeam, setHideInTeam] = React.useState(false);
    const [hideNotInTeam, setHideNotInTeam] = React.useState(false);
    const [copiedEmails, setCopiedEmails] = React.useState(false);
    const [copiedCSV, setCopiedCSV] = React.useState(false);

    const users = useMemo(() => {
        if (!hackathon) {
            return [];
        }

        return hackathon.participants.map(user => {
            const isJudge = hackathon.judges.some(j => j.email === user.email);
            const isOrganizer = hackathon.organizers.some(o => o.email === user.email);
            const team = hackathon.ideas.find(idea => idea.members.some(m => m.email === user.email))?.title;
            return {
                _id: user._id,
                name: user.username,
                email: user.email,
                team: team,
                roles: ['participant', ...(isJudge ? ['judge'] : []), ...(isOrganizer ? ['organizer'] : [])]
            }
        }).filter(user => {
            let shouldHide = false;
            if (hideJudges && !shouldHide) {
                shouldHide = user.roles.includes('judge');
            }
            if (hideInTeam && !shouldHide) {
                shouldHide = !!user.team;
            }
            if (hideNotInTeam && !shouldHide) {
                shouldHide = !user.team;
            }
            return !shouldHide;
        });
    }, [hackathon, hideJudges, hideInTeam, hideNotInTeam])

    const copyEmailAddresses = useCallback(() => {
        const emails = users.map(u => u.email).join(', ');
        navigator.clipboard.writeText(emails);
    }, [users])

    const copyUserCSV = useCallback(() => {
        const csv = users.map(u => [u.name, u.email, u.team || '', ...u.roles].join('\t')).join('\n');
        navigator.clipboard.writeText(csv);
    }, [users])

    if (!id) {
        return <div>No id provided</div>
    }

    if (!curUser) {
        return <div>Not logged in</div>
    }

    if (loading) {
        return <Loading />
    }

    if (error) {
        console.error(error);
        return <PageContainer>Error loading hackathon</PageContainer>
    }

    if (!hackathon) {
        return <PageContainer>No hackathon found</PageContainer>
    }

    if(!hackathon.organizers.some(o => o.email === curUser.email)) {
        return <PageContainer>Not an organizer</PageContainer>
    }

    return (
        <PageContainer>
            <Stack spacing={2}>
                <AppBreadcrumbs crumbs={[
                    {
                        type: PageType.Home,
                        link: '/',
                        name: 'Home'
                    },
                    {
                        type: PageType.Hackathon,
                        link: `/h/${hackathon.slug}`,
                        name: hackathon.name
                    },
                    {
                        type: PageType.Organizer,
                        name: 'Organizer Page'
                    }
                ]} />
                <h1>{hackathon.name} Organizer</h1>
                <Stack spacing={2}>
                    <Stack direction="row" alignItems='center' spacing={1}>

                        <h2>Users ({users.length})</h2>
                        <Box flex={1}></Box>
                        <FormControlLabel control={<Checkbox checked={hideJudges} onChange={(e) => setHideJudges(e.target.checked)} />} label={'Hide judges'} />
                        <FormControlLabel control={<Checkbox checked={hideInTeam} onChange={(e) => setHideInTeam(e.target.checked)} />} label={'Hide in team'} />
                        <FormControlLabel control={<Checkbox checked={hideNotInTeam} onChange={(e) => setHideNotInTeam(e.target.checked)} />} label={'Hide not in team'} />

                        <Button variant="contained"
                            disabled={copiedEmails}
                            onClick={() => {
                                setCopiedEmails(true);
                                copyEmailAddresses();
                                setTimeout(() => {
                                    setCopiedEmails(false);
                                }, 2000)
                            }}>{copiedEmails ? 'Copied' : 'Copy email addresses'}</Button>

                        <Button variant="outlined" color="secondary"
                            disabled={copiedCSV}
                            onClick={() => {
                                setCopiedCSV(true);
                                copyUserCSV();
                                setTimeout(() => {
                                    setCopiedCSV(false);
                                }, 2000);
                            }}>{copiedCSV ? 'Copied' : 'Copy Data Table'}</Button>
                    </Stack>
                    <TableContainer>
                        <Table aria-label="Users table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Team</TableCell>
                                    <TableCell>Roles</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map(user => {
                                    const isJudge = user.roles.includes('judge');
                                    const isOrganizer = user.roles.includes('organizer');
                                    return (
                                        <TableRow key={user.email}>
                                            <TableCell>{user.name}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>
                                                {
                                                    user.team && <Stack direction="row" alignItems="center">
                                                        <Typography>{user.team}</Typography>
                                                        <RemoveFromTeamButton userEmail={user.email} hackathon={hackathon} onChange={() => {
                                                            reload();
                                                        }} />
                                                    </Stack>
                                                }

                                                {
                                                    !user.team && <JoinTeamMenu userEmail={user.email} hackathon={hackathon} onChange={() => {
                                                        reload();
                                                    }} />
                                                }

                                            </TableCell>
                                            <TableCell>

                                                {user.roles.join(', ')}

                                            </TableCell>
                                            <TableCell>
                                                <Stack spacing={1} direction="row">
                                                    <MemberMenu user={user} hackathon={hackathon} actions={
                                                        [
                                                            isJudge ? 'removeJudge' : 'addJudge',
                                                            isOrganizer ? 'removeOrganizer' : 'addOrganizer',
                                                            'removeParticipant'
                                                        ]
                                                    } />

                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Stack>
        </PageContainer>
    )
}