import React from 'react';
import { HackathonPayload } from '../../services/hackathon.service';
import { IdeaPayload, removeMemberFromIdea } from '../../services/idea.service';
import Button from '@mui/material/Button/Button';
import IconButton from '@mui/material/IconButton/IconButton';
import { useState } from 'react';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import ClearIcon from '@mui/icons-material/Clear';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMemo } from 'react';

export const RemoveFromTeamButton = ({
    hackathon,
    userEmail,
    onChange
}: {
    hackathon: HackathonPayload,
    userEmail: string,
    onChange: () => void
}) => {

    const [open, setOpen] = useState(false);

    const [error, setError] = useState<string | null>(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleErrorNotificationClose = () => {
        setError(null);
    }

    const idea = useMemo(() => hackathon.ideas.find(idea => idea.members.some(m => m.email === userEmail)), [hackathon, userEmail]);

    if (!idea) {
        return null;
    }

    return (
        <>
            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={handleErrorNotificationClose}
                message="Note archived"
            />
            <IconButton title={'Remove from team'} onClick={handleClickOpen}>
                <ClearIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Remove from team?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => {
                        // Remove from team
                        removeMemberFromIdea(hackathon.slug, idea?._id, userEmail).then(() => {
                            onChange();
                            handleClose();
                        }).catch((err) => {
                            setError(err.message);
                            handleClose();
                        })
                    }} autoFocus>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    )
}