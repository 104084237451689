import React from 'react';
import { login } from '../services/auth.service';

import './Home.scss';
import { useAuth } from '../services/useAuth';
import { useDesktopMode } from '../services/hooks/useDesktopMode';
import { Loading } from '../components/Loading';
import { MyHackathons } from './HackathonPage/MyHackathons';
import { PageContainer } from '../components/PageContainer';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import { AppBreadcrumbs, PageType } from '../components/Breadcrumbs/Buttons';
import Card from '@mui/material/Card/Card';
import CardContent from '@mui/material/CardContent/CardContent';

export default function Home() {

    const desktopMode = useDesktopMode();

    const { user, loading } = useAuth();
    if (loading) {
        return <PageContainer>
            <Loading />
        </PageContainer>;
    }

    if (!user) {
        return (
            <PageContainer className="home">
                <Stack spacing={2} direction={
                    desktopMode ? 'row' : 'column'
                }>
                    <Blurb />
                </Stack>
            </PageContainer>

        )
    }

    return (
        <PageContainer className="home">
            <Stack spacing={2} direction='column'>
                <AppBreadcrumbs crumbs={
                    [
                        {
                            type: PageType.Home,
                            name: 'Home'
                        }
                    ]
                } />
                <MyHackathons />
            </Stack>
        </PageContainer>
    )

}

const Blurb = () => {
    const { user, loading } = useAuth();

    return (
        <Stack direction="column">
            <Box py={2} className="blurb site-content">
                <div className="hero-image"></div>
                <Typography variant="h2">Let us help organize your next Hackathon</Typography>
                <Box className="cta">
                    {
                        !user && <Button variant="contained" onClick={() => login()} sx={{
                            fontSize: '1.5rem',
                        }}>Sign Up or Log In to get started</Button>
                    }

                </Box>

            </Box>
            <Box sx={{
                padding: 2,
                backgroundColor: '#333'
            }}>
                <Stack spacing={4} direction="row" >
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Colleagues, friends, whomever</Typography>
                            <Typography>Protect your private event with a password</Typography>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Idea submission</Typography>
                            <Typography>Naturally form teams around ideas from participants</Typography>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Voting, simplified</Typography>
                            <Typography>No more spreadsheets, you decide on the categories, we'll tally the votes</Typography>
                        </CardContent>
                    </Card>

                </Stack>
            </Box>
        </Stack>
    )
}
