import React, { useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useIdea } from '../../hooks/useIdea';
import { Loading } from '../../components/Loading';
import { IdeaCard } from '../../components/Idea/IdeaCard';
import { useHackathon } from '../../hooks/useHackathon';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { NewComment } from '../../components/Comment/NewComment';
import { Comment } from '../../components/Comment/Comment';
import { JoinHackathon } from '../../components/Invite/JoinHackathon';
import { JudgeVoteCard } from '../../components/Voting/JudgeVoteCard';
import { useAuth } from '../../services/useAuth';
import { PageContainer } from '../../components/PageContainer';
import { LiveVotingBanner } from '../../components/Voting/LiveVotingBanner';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import { AppBreadcrumbs, PageType } from '../../components/Breadcrumbs/Buttons';
import { PresentationNavButton } from '../../components/Idea/PresentationNavButton';
dayjs.extend(relativeTime);

export const FullIdeaPage = () => {
    const {
        id,
        ideaId
    } = useParams();

    const { idea, loading, error, reload } = useIdea(id, ideaId);

    const { hackathon, loading: loadingHackathon, error: errorHackathon } = useHackathon(id);

    const [scrolled, setScrolled] = useState(false);

    const { user } = useAuth();

    const navigate = useNavigate();

    const isJudge = useMemo(() => {
        if (!user || !hackathon) {
            return false;
        }

        return hackathon.judges.some(o => o.email === user.email);
    }, [user, hackathon])

    const hash = window.location.hash;
    useEffect(() => {
        if (idea && hash === '#voting' && !scrolled) {
            document.getElementById('voting')?.scrollIntoView();
            setScrolled(true);
        }
    }, [hash, idea, scrolled])

    const inAnyTeam = useMemo(() => {
        if (!user || !hackathon) {
            return false;
        }

        return hackathon.ideas.some(idea => idea.members.some(m => m.email === user.email));
    }, [hackathon, user])

    const canJoin = useMemo(() => {
        if (!hackathon) {
            return false;
        }
        const hackathonStarted = !hackathon.startDate || (hackathon.startDate && hackathon.startDate.getTime() > Date.now());
        return Boolean(
            !!user && !inAnyTeam && !!hackathon && hackathonStarted
        )
    }, [inAnyTeam, user, hackathon])


    const votingPeriodOn = useMemo(() => {
        return hackathon?.votingPeriodOn;
    }, [hackathon])

    if (!user) {
        return <PageContainer>
            <Stack spacing={2}>
                <h1>Not logged in</h1>
            </Stack>
        </PageContainer>
    }

    if (loading || loadingHackathon) {
        return <Loading />
    }

    if (!id || !ideaId) {
        return <PageContainer>
            <Stack spacing={2}>
                <h1>No hackathon or idea id provided</h1>
            </Stack>
        </PageContainer>
    }

    if (errorHackathon) {
        const data = (errorHackathon.response as any).data;
        const needsInviteCode = data.needsInviteCode || true;

        return <PageContainer>
            <Stack spacing={2}>
                <JoinHackathon hackathonId={id} needsInviteCode={needsInviteCode} />
            </Stack>
        </PageContainer>
    }

    if (error) {
        return <PageContainer>
            <Stack spacing={2}>
                <h1>Error loading idea</h1>
            </Stack>
        </PageContainer>
    }


    if (!idea || !hackathon) {
        return <PageContainer>
            <Stack spacing={2}>
                <h1>Idea not found</h1>
            </Stack>
        </PageContainer>
    }

    return (
        <PageContainer>
            <Stack spacing={2}>
                <LiveVotingBanner hackathonId={hackathon._id} ideaId={idea._id} />
                <AppBreadcrumbs crumbs={[
                    {
                        type: PageType.Home,
                        link: '/',
                        name: 'Home'
                    },
                    {
                        type: PageType.Hackathon,
                        link: `/h/${id}`,
                        name: hackathon?.name || ''
                    },
                    {
                        type: PageType.Idea,
                        name: idea.title
                    }
                ]} />

                <IdeaCard
                    ideaId={idea._id}
                    hackathonId={id}
                    maxTeamSize={hackathon?.maxTeamSize}
                    isOrganizer={hackathon?.organizers.some(o => o.email === user.email)}
                    canJoin={canJoin}
                    onDelete={() => {
                        // redirect to hackathon page
                        navigate(`/h/${id}`);
                    }}
                    onReloadIdea={() => {
                        // reload the idea
                        reload();
                    }}
                />
                {
                    (hackathon.participantScores || isJudge) && hackathon.votingPeriodOn && (
                        <Stack direction="column" spacing={1} id="voting">
                            <br />
                            <br />
                            <h3>Your vote</h3>
                            <JudgeVoteCard hackathon={hackathon} idea={idea} />
                        </Stack>
                    )
                }
                <Stack spacing={2} direction="column" sx={{
                    backgroundColor: '#f0f0f0'
                }} padding={2}>
                    <h3>Comments ({idea.comments.length})</h3>
                    {
                        idea.comments.map(comment => {
                            return <Comment key={comment._id} {...comment} />
                        })
                    }
                    {
                        idea.comments.length === 0 && <Typography>No comments yet</Typography>
                    }
                    <Stack spacing={2} direction="column">
                        <NewComment hackathonId={id} ideaId={ideaId} submitted={() => {
                            reload();
                        }} />
                    </Stack>
                </Stack>
                {
                    votingPeriodOn && (
                        <PresentationNavButton hackathonId={hackathon.slug} />
                    )
                }
            </Stack>
        </PageContainer>
    )
}