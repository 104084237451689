import React from 'react';
import { User } from '../../services/auth.service';
import { HackathonPayload } from '../../services/hackathon.service';
import { useRef } from 'react';
import Button from '@mui/material/Button/Button';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { addMemberToIdea } from '../../services/idea.service';
import Snackbar from '@mui/material/Snackbar/Snackbar';

export const JoinTeamMenu = ({
    userEmail,
    hackathon,
    onChange
}: {
    userEmail: string,
    hackathon: HackathonPayload,
    onChange: () => void
}) => {

    const availableIdeas = hackathon.ideas.filter(idea => !idea.archived && !idea.disqualified);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleErrorNotificationClose = () => {
        setError(null);
    }

    const [error, setError] = React.useState<string | null>(null);

    return <>
        <Snackbar
            open={!!error}
            autoHideDuration={2000}
            onClose={handleErrorNotificationClose}
            message={error}
        />
        <Button onClick={handleClick}>
            Add to Team
        </Button>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}>
            {availableIdeas.map(idea => <MenuItem key={idea._id} onClick={() => {
                // Join team
                addMemberToIdea(hackathon.slug, idea._id, userEmail).then(() => {
                    onChange();
                    handleClose();
                }).catch((err) => {
                    setError(err.message);
                    handleClose();
                })
            }}>
                {idea.title}</MenuItem>)}
        </Menu>
    </>
}