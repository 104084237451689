import Button from '@mui/material/Button/Button';
import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';
import PollIcon from '@mui/icons-material/Poll';
import GavelIcon from '@mui/icons-material/Gavel';
import PeopleIcon from '@mui/icons-material/People';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArticleIcon from '@mui/icons-material/Article';

import Typography from '@mui/material/Typography/Typography';
import { Link } from 'react-router-dom';
import { QRCodeDialog } from '../QRContainer';
import Breadcrumbs from '@mui/material/Breadcrumbs/Breadcrumbs';
import Stack from '@mui/material/Stack/Stack';

const buttonStyle = {
    alignItems: 'center',
    display: 'flex',
    color: 'inherit'
}

export const HomeButton = () => {
    return (
        <Button component={Link} to="/" sx={buttonStyle}>
            <HomeIcon />
            <Typography>Home</Typography>
        </Button>
    )
}

export const HackathonButton = ({
    hackathonId,
    hackathonName
}: {
    hackathonId: string,
    hackathonName: string
}) => {
    return (
        <Button component={Link} to={`/h/${hackathonId}`} sx={buttonStyle}>
            <CodeIcon />
            <Typography>
                {hackathonName}
            </Typography>
        </Button>
    )
}

export enum PageType {
    Home,
    Hackathon,
    Idea,
    Organizer,
    Judge,
    HowTo,
    Article,
    Results,
    PresentationOrder
}

const iconMap = {
    [PageType.Home]: <HomeIcon />,
    [PageType.Hackathon]: <CodeIcon />,
    [PageType.Idea]: <LightbulbIcon />,
    [PageType.Organizer]: <PeopleIcon />,
    [PageType.Judge]: <GavelIcon />,
    [PageType.HowTo]: <HelpCenterIcon />,
    [PageType.Article]: <ArticleIcon />,
    [PageType.Results]: <PollIcon />,
    [PageType.PresentationOrder]: <LowPriorityIcon />
}

export const AppBreadcrumbs = ({
    crumbs
}: {
    crumbs: Array<{
        type: PageType,
        name: string,
        link?: string,
    }>
}) => {

    return (
        <Breadcrumbs sx={{ py: 1 }}>
            {
                crumbs.map((crumb, index) => {
                    if (crumb.link) {
                        return (
                            <Button key={index} component={Link} to={crumb.link} sx={buttonStyle}>
                                {iconMap[crumb.type]}
                                <Typography>{crumb.name}</Typography>
                                {
                                    index === crumbs.length - 1 && <QRCodeDialog />
                                }
                            </Button>
                        )
                    }

                    return (
                        <Stack key={index} direction="row" alignItems="center" spacing={0.5} sx={buttonStyle}>
                            {iconMap[crumb.type]}
                            <Typography>{crumb.name}</Typography>
                            {
                                index === crumbs.length - 1 && <QRCodeDialog />
                            }
                        </Stack>
                    )
                })
            }
        </Breadcrumbs>
    )

}